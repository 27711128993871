import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProfileState {
  authorized: boolean,

  name: string;
  gender: string;
  birthYear: number;
  user_id: string | null;

  selectedQuizHabits: any[],
  age: string | null;
  email: string,
  is_unsubscriber: number | boolean | null
}


const initialState: ProfileState = {
  authorized: false,

  name: 'Aspirer', // send to back
  gender: '', // send to back
  birthYear: 0, // send to back
  user_id: null,
  age: null,
  selectedQuizHabits: [],

  email: '',
  is_unsubscriber: null
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setGender: (state, action: PayloadAction<string>) => {
      state.gender = action.payload;
    },
    setAuthorized: (state) => {
      state.authorized = true;
    },
    setSelectedQuizHabits: (state, action: PayloadAction<any[]>) => {
      state.selectedQuizHabits = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setBirthYear: (state, action: PayloadAction<number>) => {
      state.birthYear = action.payload;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.user_id = action.payload;
    },
    setAge: (state, action: PayloadAction<string>) => {
      state.age = action.payload;
    },
    setIsUnsubscriber: (state, action: PayloadAction<any>) => {
      state.is_unsubscriber = action.payload;
    }
  }
})

export const blacklist = ['authorized'];

export { slice as profileSlice };
