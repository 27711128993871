import _uniq from 'lodash/uniq';
import { ThunkAction } from 'redux-thunk';

//@ts-ignore
import Billing from '@web-solutions/module-billing';

import Analytics from '@web-solutions/module-analytics';

import { updateProfile } from '@web-solutions/base-app/api/profile'

import { stringifyUrlParams } from '@web-solutions/core/utils/url-sync';
import { setApiAuth } from '@web-solutions/core/utils/network';

import * as API from 'src/api/auth';
import { updateUserEmail } from 'src/api/auth';
import { createUserHabits } from 'src/api/habits';
import { habitsSelector, routinesSelector } from 'src/screens/quiz/questions/data/selectors';

import { BASE_COURSE_VERSION, HABITS, ROUTINES } from 'src/constants/habits';

import { setPending } from '../app/actions';

import { profileSlice } from '.';

export const {
  setAuthorized,
  setName,
  setGender,
  setBirthYear,
  setUserId,
  setEmail,
  setIsUnsubscriber,
  setSelectedQuizHabits,
  setAge,
} = profileSlice.actions;

export const processAge = (age: string): ThunkAction<void, any, unknown, any> => (dispatch, getState) => {
  Analytics.setUserProperty('age', age);
  dispatch(setAge(age));
}

export const processGender = (gender: string): ThunkAction<void, any, unknown, any> => (dispatch, getState) => {
  Analytics.setUserProperty('gender', gender);
  dispatch(setGender(gender));
}

export const auth =
  (data: any = {}): ThunkAction<Promise<any>, any, unknown, any> =>
    async (dispatch, getState) => {
      return API.auth()
        .then(([user, billingAuth]) => {
          Billing.setApiAuth(`${billingAuth.token_type} ${billingAuth.access_token}`);
          setApiAuth(`Bearer ${user.access_token}`);
          dispatch(setUserId(user.id));
          dispatch(setAuthorized());
        });
    };

export const updateUserData = (): ThunkAction<Promise<void>, any, unknown, any> =>
  async (dispatch, getState) => {
    const {
      profile: { name, gender, birthYear, email, is_unsubscriber },
    } = getState();

    dispatch(setPending(true));

    try {
      const userData = {} as Record<string, string>;

      userData.name = name;
      userData.gender = gender;
      userData.birth_year = birthYear;

      try {
        const user = await API.updateUserData(userData);
        setApiAuth(`Bearer ${user.access_token}`);

        await updateProfile({ email, is_unsubscriber });

        await dispatch(pushUserHabits());
      } catch (err) {
        console.log('[ERROR AUTH]', err);
        throw err;
      }
    } catch (error: any) {
      if (error?.status !== 422) {
        throw error;
      }
      console.log('[ERROR AUTH AND SET USER DATA]', error);
    } finally {
      console.log(`DataFinish: ${new Date()}`);
      dispatch(setPending(false));
    }
  };

export const pushUserHabits = (): ThunkAction<Promise<void>, any, unknown, any> => async (dispatch, getState) => {
  try {
    const state = getState();

    let habits = habitsSelector(state)
      .map((h => HABITS[h.key]));

    routinesSelector(state)
      .forEach((r => {
        habits.push(ROUTINES[r.key]);
      }));

    habits = _uniq(habits).filter(Boolean);

    if (habits.length) {
      await createUserHabits({
        habits: habits.map(h => ({
          habit_id: h,
          base_course_version: BASE_COURSE_VERSION,
        }))
      });
    }
  } catch (error) {
    console.log('[ERROR SET HABITS]', error);
    throw error;
  }
};

export const processEmail = (email: string, options?: { skipUrlSync: boolean }): ThunkAction<void, any, unknown, any> =>
  (dispatch, getState) => {
    if (email !== getState().profile.email) {
      Analytics.setUserProperty('email', email);
      Analytics.trackEvent('user', 'info', { email, });

      dispatch(setEmail(email));
      if (!options?.skipUrlSync) {
        stringifyUrlParams({ email });
      }

      Analytics.trackEvent('firebase_auth', 'update_email_submit', { email });
      updateUserEmail(email)
        .then(() => {
          Analytics.trackEvent('firebase_auth', 'update_email_success', { email });
        })
        .catch((error) => {
          Analytics.trackEvent('firebase_auth', 'update_email_error', { error: error?.message, code: error?.code, email });
        });
    }
  }
