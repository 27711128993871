import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { faceReadingSlice } from '@web-solutions/face-reading/src/store';
import { terminateSlice, manageSlice } from '@web-solutions/manage-subscription';

import { accountManagementSlice, accountManagementSliceBlacklist } from '@web-solutions/account-management';

import remoteConfig from 'core/store/remote-config';
import billing from 'core/store/billing';
import { quizSlice } from 'core/store/quiz';

import { appSlice, blacklist as appBlacklist } from './app';
import { routingSlice, whitelist as routingWhitelist } from './routing';
import { profileSlice, blacklist as profileBlacklist } from './profile';

const configureStore = () => {
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const keyPrefix = `quiz-pp::`;

  const rootReducer = combineReducers({
    app: persistReducer(
      {
        key: `app`,
        storage,
        blacklist: appBlacklist,
      },
      appSlice.reducer,
    ),
    remoteConfig: persistReducer(
      {
        key: `${keyPrefix}remoteConfig`,
        storage,
        blacklist: remoteConfig.blacklist,
        whitelist: remoteConfig.whitelist,
      },
      remoteConfig,
    ),
    routing: persistReducer(
      {
        key: `${keyPrefix}routing`,
        storage,
        whitelist: routingWhitelist
      },
      routingSlice.reducer,
    ),
    billing: persistReducer(
      {
        key: `${keyPrefix}billing`,
        storage,
        blacklist: billing.blacklist,
        whitelist: billing.whitelist,
      },
      billing,
    ),
    profile: persistReducer(
      {
        key: `${keyPrefix}profile`,
        storage,
        blacklist: profileBlacklist,
      },
      profileSlice.reducer,
    ),
    quiz: persistReducer(
      {
        key: `${keyPrefix}quiz`,
        storage,
      },
      quizSlice.reducer,
    ),
    [terminateSlice.name]: terminateSlice.reducer,
    [manageSlice.name]: manageSlice.reducer,
    [faceReadingSlice.name]: persistReducer({
      key: `${keyPrefix}${faceReadingSlice.name}`,
      storage,
    }, faceReadingSlice.reducer),
    [accountManagementSlice.name]: persistReducer({
      key: `${keyPrefix}${accountManagementSlice.name}`,
      storage,
      blacklist: accountManagementSliceBlacklist,
    }, accountManagementSlice.reducer),
  });

  const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(thunk)));

  const persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
