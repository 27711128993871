import _mapValues from 'lodash/mapValues';

import { AfterPurchaseStep, AfterPurchaseRoutes, QUIZ_NAMES, SUBSCRIPTION_NAMES } from './screens';

export const SUBSCRIPTION = {
  [SUBSCRIPTION_NAMES.PRESUMMARY]: '/subscription/presummary',
  [SUBSCRIPTION_NAMES.MAIN]: '/subscription/main',
  [SUBSCRIPTION_NAMES.SUCCESS]: '/subscription/success',
  [SUBSCRIPTION_NAMES.GUIDES]: '/subscription/guides',
  [SUBSCRIPTION_NAMES.ADHD_WORKBOOK]: '/subscription/adhd_workbook',
  [SUBSCRIPTION_NAMES.ULTIMATE_GUIDE]: '/subscription/ultimate_guide',
  [SUBSCRIPTION_NAMES.CREATE_ACCOUNT]: '/subscription/create_account',
};

export const QUIZ = _mapValues(QUIZ_NAMES, (v) => '/' + v.toLowerCase() + '/*');

export const ROUTES = {
  ...QUIZ,
  ...SUBSCRIPTION,
};

export const SCREENS_WITHOUT_PROGRESSBAR = [
  QUIZ_NAMES.QUIZ_TRUST_TRANSITION_SPECIALIST,
  QUIZ_NAMES.QUIZ_EMAIL,
  QUIZ_NAMES.QUIZ_TRANSITION_ADHD_SUMMARY,
  QUIZ_NAMES.QUIZ_SURVEY,
  QUIZ_NAMES.QUIZ_TRANSITION_ADHD_FACE_INFO,
  QUIZ_NAMES.QUIZ_TRANSITION_DETOX_FACE_INFO,
  QUIZ_NAMES.QUIZ_TRANSITION_PROCRASTINATION_FACE_INFO,
];

const AFTER_PURCHASE: { [key in AfterPurchaseStep]: void } = {
  [SUBSCRIPTION_NAMES.GUIDES]: undefined,
  [SUBSCRIPTION_NAMES.ADHD_WORKBOOK]: undefined,
  [SUBSCRIPTION_NAMES.ULTIMATE_GUIDE]: undefined,
  [SUBSCRIPTION_NAMES.CREATE_ACCOUNT]: undefined,
}

export const AFTER_PURCHASE_ROUTES = Object.keys(AFTER_PURCHASE) as AfterPurchaseRoutes;
