export const DARK_FORM_STYLES = {}

export const SUBMIT_BUTTON_COLOR = '#ee8f61';
export const SUBMIT_BUTTON_COLOR_LIGHT_THEME = '';
export const SUBMIT_BUTTON_COLOR_DARK_THEME = '';
export const SUBMIT_BUTTON_COLOR_MONOCHROME = '#16786C';

export const SUBMIT_BUTTON_BORDER_RADIUS_MONOCHROME = '';
export const SUBMIT_BUTTON_BORDER_RADIUS_LIGHT_THEME = '';
export const SUBMIT_BUTTON_BORDER_RADIUS_DARK_THEME = '';
export const SUBMIT_BUTTON_BORDER_RADIUS = '';
